import React, { useState, useEffect, useMemo } from "react"
import * as Docxtemplater from "docxtemplater"
import PizZip from "pizzip"
import axios from "axios"
import {
  Card,
  CardHeader,
  CardBody,
  Col,
  Row,
  Table,
  Collapse,
  Form,
} from "reactstrap"
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer"
import classnames from "classnames"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
// import { readFileSync } from 'html-docx-js';

const FileConverter = props => {
  const [loading, setLoading] = useState()
  const [jawaban, setJawaban] = useState()
  const [jawabanUrut, setJawabanUrut] = useState()
  const [multiSoal, setMultiSoal] = useState([])
  const [tipe, setTipe] = useState()
  const [fileJawabanPpt, setFileJawabanPpt] = useState()
  const [fileJawabanPdf, setFileJawabanPdf] = useState()
  const [fileJawabanInterview, setFileJawabanInterview] = useState()
  const url = process.env.REACT_APP_BACKEND_URL
  const [text, setText] = useState("")
  const [accordionOpen, setAccordionOpen] = useState([])

  const toggleAccordion = index => {
    const updatedAccordionOpen = [...accordionOpen]
    updatedAccordionOpen[index] = !updatedAccordionOpen[index]
    setAccordionOpen(updatedAccordionOpen)
  }

  const handleGetJawaban = () => {
    const baseURLGet =
      process.env.REACT_APP_BACKEND_URL_VERSION +
      `jawaban/jawaban-peserta?id_peserta=` +
      props.idPeserta +
      `&id_simulasi=` +
      props.idSimulasi +
      `&id_jadwal=` +
      props.idJadwal
    fetch(baseURLGet, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("access_token"),
        "Content-Type": "application/json",
      },
    })
      .then(response => response.json())
      .then(responseData => {
        // console.log(responseData)
        if (responseData.meta.code === 200) {
          if (responseData.data[0]) {
            const value_asesor = responseData.data[0].value_asesor
            setText(value_asesor)
            const data = responseData.data[0].data
            getTipeJawaban(data)
            setLoading(false)
          }
        } else {
          getTipeJawaban("PPT")
        }
      })
      .catch(error => {
        console.log(error)
      })
  }
  useEffect(() => {
    handleGetJawaban()
  }, [])

  const getTipeJawaban = data => {
    if (data === "PPT") {
      setTipe("PPT")
      const baseURLGet =
        process.env.REACT_APP_BACKEND_URL_VERSION +
        `jawaban/jawaban-peserta?id_peserta=` +
        props.idPeserta +
        `&id_simulasi=` +
        props.idSimulasi +
        `&id_jadwal=` +
        props.idJadwal +
        `&tipe=` +
        "Tipe 4"
      fetch(baseURLGet, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("access_token"),
          "Content-Type": "application/json",
        },
      })
        .then(response => response.json())
        .then(responseData => {
          if (responseData.meta.code === 200) {
            setFileJawabanPpt(responseData.data[0].dir)
          } else {
          }
        })
        .catch(error => {
          console.log(error)
        })
    } else {
      const baseURLGet =
        process.env.REACT_APP_BACKEND_URL_VERSION +
        `jawaban/jawaban-peserta?id_peserta=` +
        data.id_peserta +
        `&id_simulasi=` +
        data.id_msm +
        `&id_jadwal=` +
        data.jp_id_jadwal +
        `&tipe=` +
        data.tipe
      fetch(baseURLGet, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("access_token"),
          "Content-Type": "application/json",
        },
      })
        .then(response => response.json())
        .then(responseData => {
          if (responseData.meta.code === 200) {
            setTipe(data.tipe)
            if (data.tipe === "Tipe 7") {
              setFileJawabanPdf(responseData.data[0])
            } else if (data.tipe == "Tipe 2") {
              const multiSoalJawaban = JSON.parse(data.value)
              setMultiSoal(multiSoalJawaban)
            } else if (data.tipe == "Tipe 6") {
              setJawabanUrut(responseData.data[0].data_soal)
              setJawaban(data.value)
            } else if (data.tipe == "Tipe 8") {
              setFileJawabanInterview(data.value)
            } else {
              setJawaban(data.value)
            }
          } else {
            setJawaban(data.value)
          }
        })
        .catch(error => {
          console.log(error)
        })
    }
  }

  // const handleFileChange = async event => {
  //   const file = event.target.files[0]

  //   if (file) {
  //     const reader = new FileReader()

  //     reader.onload = async event => {
  //       const arrayBuffer = event.target.result

  //       const zip = new PizZip(arrayBuffer)
  //       const doc = new create()
  //       doc.loadZip(zip)

  //       const content = doc.getFullText()
  //       setText(content)

  //       const baseURLGet =
  //         process.env.REACT_APP_BACKEND_URL_VERSION +
  //         `jawaban/jawaban-peserta?id_peserta=` +
  //         props.idPeserta +
  //         `&id_simulasi=` +
  //         props.idSimulasi +
  //         `&id_jadwal=` +
  //         props.idJadwal +
  //         `&jawaban_asesor=` +
  //         content
  //       fetch(baseURLGet, {
  //         method: "PUT",
  //         headers: {
  //           Authorization: "Bearer " + localStorage.getItem("access_token"),
  //           "Content-Type": "application/json",
  //         },
  //       })
  //         .then(response => response.json())
  //         .then(responseData => {
  //           // console.log(responseData)
  //           if (responseData.meta.code === 200) {
  //             handleGetJawaban()
  //           }
  //         })
  //         .catch(error => {
  //           console.log(error)
  //         })
  //     }

  //     reader.readAsArrayBuffer(file)
  //   }
  // }

  const memoizedDocComponent = useMemo(() => {
    if (tipe === "PPT") {
      return (
        <div style={{ height: 500 }}>
          {fileJawabanPpt != null ? (
            <DocViewer
              documents={[
                {
                  uri: url + fileJawabanPpt,
                  type: "pptx",
                  fileName: "Jawaban PowerPoint",
                },
              ]}
              pluginRenderers={DocViewerRenderers}
            />
          ) : (
            <p>Jawaban peserta tidak ada</p>
          )}
        </div>
      )
    } else if (tipe === "Tipe 7") {
      if (fileJawabanPdf && fileJawabanPdf.length > 0) {
        return (
          <div className="accordion accordion-flush" id="accordionFlushExample">
            {fileJawabanPdf.map((item, index) => (
              <div key={index} className="accordion-item">
                <h2 className="accordion-header" id={`headingFlush${index}`}>
                  <button
                    className={classnames("accordion-button", "fw-medium", {
                      collapsed: !accordionOpen[index],
                    })}
                    type="button"
                    onClick={() => toggleAccordion(index)}
                    style={{ cursor: "pointer" }}
                  >
                    File Upload {index + 1}
                  </button>
                </h2>
                <Collapse
                  isOpen={accordionOpen[index]}
                  className="accordion-collapse"
                >
                  <div className="accordion-body">
                    <div className="text-muted">
                      <DocViewer
                        className="my-doc-viewer-style"
                        documents={[
                          {
                            uri: url + item.juf_file_dir,
                          },
                        ]}
                        documentLoading={false}
                        config={{
                          loader: false,
                          header: {
                            disableHeader: false,
                            disableFileName: false,
                            retainURLParams: false,
                          },
                          csvDelimiter: ",", // "," as default,
                          pdfZoom: {
                            defaultZoom: 1.1, // 1 as default,
                            zoomJump: 0.2, // 0.1 as default,
                          },
                          pdfVerticalScrollByDefault: true, // false as default
                        }}
                        pluginRenderers={DocViewerRenderers}
                        style={{ height: 900 }}
                      />
                    </div>
                  </div>
                </Collapse>
              </div>
            ))}
          </div>
        )
      } else {
        return <p>No files available.</p>
      }
    } else if (tipe === "Tipe 6") {
      return (
        <Row className="p-3">
          <Col xl="6">
            <h4 className="fw-bold fs-5">Jawaban Text</h4>
            <Form>
              <CKEditor
                editor={ClassicEditor}
                data={jawaban}
                disabled={true}
                config={{
                  toolbar: [
                    "bold",
                    "italic",
                    "bulletedList",
                    "|",
                    "numberedList",
                    "alignment",
                    "link", // Add the "link" option to include the Link plugin
                  ],
                  removePlugins: ["Heading"],
                  isReadOnly: true,
                }}
              />
            </Form>
          </Col>
          <Col xl="6">
            <h4 className="fw-bold fs-5">Jawaban mengurutkan</h4>
            <Table className="table mb-0">
              <tbody>
                {(jawabanUrut || []).map((item, index) => (
                  <tr key={index}>
                    <th scope="row" className="drag-pointer">
                      {item}
                    </th>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      )
    } else if (tipe === "Tipe 2") {
      return (
        <Table className="table mb-0">
          <tbody>
            {Object.keys(multiSoal).map(key => (
              <tr key={key}>
                <th>Soal : {key}: </th>
                <td>
                  <Form>
                    <CKEditor
                      editor={ClassicEditor}
                      data={multiSoal[key]}
                      disabled={true}
                      config={{
                        toolbar: [
                          "bold",
                          "italic",
                          "bulletedList",
                          "|",
                          "numberedList",
                          "alignment",
                          "link", // Add the "link" option to include the Link plugin
                        ],
                        removePlugins: ["Heading"],
                        isReadOnly: true,
                      }}
                    />
                  </Form>
                </td>
              </tr>
            ))}{" "}
          </tbody>
        </Table>
      )
    } else if (tipe === "Tipe 8") {
      return (
        <div style={{ height: 500 }}>
          <DocViewer
            documents={[
              {
                uri: url + fileJawabanInterview,
                fileName: "Jawaban Interview",
              },
            ]}
            pluginRenderers={DocViewerRenderers}
          />
        </div>
      )
    } else {
      return (
        <Form>
          <CKEditor
            editor={ClassicEditor}
            data={jawaban}
            disabled={true}
            config={{
              toolbar: [
                "bold",
                "italic",
                "bulletedList",
                "|",
                "numberedList",
                "alignment",
                "link", // Add the "link" option to include the Link plugin
              ],
              removePlugins: ["Heading"],
              isReadOnly: true,
            }}
          />
        </Form>
      )
    }
  }, [tipe, fileJawabanPdf, url, fileJawabanPpt, jawaban, accordionOpen])

  const handleFileChange = e => {
    setLoading(true)
    const myHeaders = new Headers()
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("access_token")
    )

    const formdata = new FormData()
    formdata.append("id_simulasi", props.idSimulasi)
    formdata.append("id_peserta", props.idPeserta)
    formdata.append("id_jadwal", props.idJadwal)
    formdata.append("word_file", e.target.files[0], e.target.files[0].name)

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    }
    const baseURL =
      process.env.REACT_APP_BACKEND_URL_VERSION + `penilaian/convert`
    fetch(baseURL, requestOptions)
      .then(response => response.json())
      .then(responseData => {
        if (responseData.meta.code === 200) {
          handleGetJawaban()
        }
      })
      .catch(error => console.log("error", error))
  }

  return (
    <div>
      <Card>
        <CardHeader className="bg-light rounded">
          <Row>
            <Col xl={6} className="text-start">
              <p className="fw-medium">{props.nama_simulasi}</p>
            </Col>
            <Col xl={6} className="text-end">
              <input
                type="file"
                accept=".doc, .docx"
                onChange={e => handleFileChange(e)}
                className="form-control"
              />
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          {loading ? (
            <div className="ms-2 mt-2 fw-bold">
              <div
                role="status"
                className="spinner-border text-dark spinner-border-sm"
              >
                <span className="visually-hidden">Loading...</span>
              </div>{" "}
              Loading...
            </div>
          ) : (
            <>
              {text ? (
                <div dangerouslySetInnerHTML={{ __html: text }} />
              ) : (
                <p className="mb-3">No generated content yet.</p>
              )}
              <div>{memoizedDocComponent}</div>
            </>
          )}
        </CardBody>
      </Card>
    </div>
  )
}

export default FileConverter
